import React from 'react'
import { CarouselSpacing } from './CarouselSpacing'

const BodyMiddle = () => {
  return (
    <div className='bg-gradient-to-r from-[#6F64DD] flex justify-center border-transparent items-start to-[#0D070F]  h-full '>

      <span>
        <h1 className='text-3xl ml-10  md:text-5xl text-white font-bold mt-20'>Use PixaTwist today for</h1>
        <span className='mt-10 md:mt-20'> <CarouselSpacing /></span>
      </span>
    </div>
  )
}

export default BodyMiddle