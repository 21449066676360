import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion.jsx"

export function AccordionDemo() {
  return (
    <Accordion type="single" collapsible className="w-full max-w-xl mx-auto">
      <AccordionItem value="item-1">
        <AccordionTrigger>Can I use the images for commercial purposes?</AccordionTrigger>
        <AccordionContent>
          Yes, premium users may use images commercially without restrictions.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>Is there a free trial?</AccordionTrigger>
        <AccordionContent>
          Yes, new users can enjoy a 3-day free trial with access to basic features.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger>How do I cancel my subscription?</AccordionTrigger>
        <AccordionContent>
          You can cancel anytime via your subscription iTune Account/Play Store Account.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-4">
        <AccordionTrigger>What features are available during the trial period?</AccordionTrigger>
        <AccordionContent>
          During the 3-day free trial, users have access to basic features of the PixaTwist App.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-5">
        <AccordionTrigger>Can I upgrade my plan during the trial?</AccordionTrigger>
        <AccordionContent>
          Yes, you can upgrade to a premium plan at any time during or after the trial period.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-6">
        <AccordionTrigger>Are there any watermarks on the images?</AccordionTrigger>
        <AccordionContent>
          Free trial users will have a watermark on images, while premium users can download images without watermarks.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-7">
        <AccordionTrigger>What payment methods are accepted?</AccordionTrigger>
        <AccordionContent>
          We accept payments through the App Store and Play Store.
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
