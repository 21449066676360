import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scroll
    });
  };
  const navigate = useNavigate();

  // Function to open the Play Store link
  const openPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=com.pixatwist', '_blank');
  };

  return (
    <div className='bg-[#05020E] p-4'>
      <div className='flex flex-col md:flex-row justify-center md:justify-evenly gap-4 md:gap-10 items-center'>
        <div>
          <div className='flex flex-col md:flex-row md:gap-12'>
            <span className='cursor-pointer mb-4 md:mb-0'>
              <p className='text-[#FFFFFF] font-bold text-lg md:text-xl' onClick={scrollToTop}>PixaTwist</p>
              <p className='text-[#E0E0E1] text-sm md:text-lg mt-2 cursor-pointer' onClick={scrollToTop}>Home</p>
              <p className='text-[#E0E0E1] text-sm md:text-lg mt-2 cursor-pointer' onClick={openPlayStore}>Mobile App</p>
            </span>

            <span>
              <p className='text-[#FFFFFF] font-bold text-lg md:text-xl'>Legal</p>
              <p className='text-[#E0E0E1] text-sm md:text-lg mt-3 cursor-pointer hover:text-yellow-300' onClick={() => navigate("/privacypolicy")}>Privacy Policy</p>
              <p className='text-[#E0E0E1] text-sm md:text-lg mt-2 cursor-pointer hover:text-yellow-300' onClick={() => navigate("/termofuse")}>Terms and Conditions</p>
            </span>
          </div>
          <p className='text-[#E0E0E1] text-sm mt-4 md:text-lg md:mt-8'>Endless Possibilities. Just Imagine.</p>
          <p className='text-[#8F9095] text-sm mt-2 md:text-lg md:mt-4'>© 2024 PixaTwist AI. All rights reserved.</p>
        </div>

        <div className='flex gap-2 mt-2 md:mt-0 md:flex md:gap-4'>
          <img
            src='/playstore-coming-soon.svg'
            className='brxe-image css-filter size-full lazyloaded w-32 md:w-60 cursor-pointer'
            onClick={openPlayStore}
            alt='Download on Play Store'
            id='brxe-zuwcim'
            decoding='async'
          />
        </div>
        <img
          alt='astronaut'
          loading='lazy'
          decoding='async'
          data-nimg='1'
          className='w-40 h-40 md:w-60 md:h-60 size-full translate-y-6 floating'
          style={{ color: 'transparent' }}
          src='/astronaut.webp'
        />
      </div>
    </div>
  );
};

export default Footer;
