import React from 'react'
import { AccordionDemo } from './Accordion'

const FAQs = () => {
  const navigator = () => {
    window && window.open('https://play.google.com/store/apps/details?id=com.pixatwist', '_blank')
  }
  return (
    <div id="FAQs">
      <div className='md:flex pt-6 pb-6 md:pb-10 md:pt-0 text-white justify-around bg-[#090A0C] items-center h-full'>
        <div>
          <h1 className='ml-8 text-3xl md:ml-0 md:text-5xl font-semibold'>Have questions?</h1>
          <h1 className='ml-8 md:ml-0 text-xl md:text-5xl text-[#BFC0C0] font-semibold'>We have answers</h1>
          <h4 className='ml-8 md:ml-0 text-xs md:text-sm text-[#A9A9AA] font-semibold mt-2 md:mt-4'>Want to know more? You can email us anytime at</h4>
          <h4 className='ml-8 md:ml-0 text-xs md:text-sm text-[#FF7F2A] font-semibold '>support@pixatwist.com</h4>
          <button onClick={navigator} className='bg-[#8850FF] md:px-4 md:py-2 px-2 py-1 ml-8 md:ml-0 rounded-lg text-white mt-2 md:mt-4'>Launch App</button>
        </div>

        <div className='mt-10 ml-10 md:w-[50%]'>
          <AccordionDemo />
        </div>
      </div>
    </div>
  )
}

export default FAQs