import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';

const BodyUpper = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='bg-gradient-to-r from-[#6E66E3] to-[#91364A] min-h-screen'>

      <nav className='flex items-center justify-between text-white text-lg p-4'>
        <img
          src='/PixaLogo2.png'
          className='bricks-site-logo css-filter lazyloaded w-40 md:w-60 cursor-pointer'
          alt='AI Art Generator - Create Art, Images & More | PixaTwist'
          onClick={() => navigate('/')}
          loading='eager'
          decoding='async'
        />

        <div className='hidden md:flex gap-8'>
          <Link className="cursor-pointer hover:text-gray-300 hover:underline" to="Home" smooth={true} duration={500}>Home</Link>
          <Link className="cursor-pointer hover:text-gray-300 hover:underline" to="Gallery" smooth={true} duration={500}>Gallery</Link>
          <Link className="cursor-pointer hover:text-gray-300 hover:underline" to="FAQs" smooth={true} duration={500}>FAQs</Link>
          <Link className="cursor-pointer hover:text-gray-300 hover:underline" to="Reviews" smooth={true} duration={500}>Reviews</Link>
          <Link className="cursor-pointer hover:text-gray-300 hover:underline" to="FAQs" smooth={true} duration={500}>Contact</Link>
        </div>

        <div className='md:hidden'>
          <button onClick={toggleMenu}>
            <img src="/menu.png" alt="Menu" className="w-8 h-8" />
          </button>
        </div>

        {isOpen && (
          <div className='absolute top-16 right-0 bg-black p-4 rounded-lg shadow-lg flex flex-col items-start z-50'>
            <Link className="cursor-pointer hover:text-gray-300 hover:underline" to="Home" smooth={true} duration={500}>Home</Link>
            <Link className="cursor-pointer hover:text-gray-300 hover:underline" to="Gallery" smooth={true} duration={500}>Gallery</Link>
            <Link className="cursor-pointer hover:text-gray-300 hover:underline" to="FAQs" smooth={true} duration={500}>FAQs</Link>
            <Link className="cursor-pointer hover:text-gray-300 hover:underline" to="Reviews" smooth={true} duration={500}>Reviews</Link>
            <Link className="cursor-pointer hover:text-gray-300 hover:underline" to="FAQs" smooth={true} duration={500}>Contact</Link>

          </div>
        )}
      </nav>
      {/* </div> */}

      <div className='flex flex-col md:flex-row justify-center items-center mt-10'>
        <div className='bg-[#887FFA] p-4 w-60 rounded-xl h-64 md:w-72 md:h-80 md:rounded-xl rotate-12 m-8'>
          <img
            className='brxe-image img-plx skip-lazy css-filter rounded-xl cursor-all-scroll floating w-52 h-52 md:w-64 md:h-72'
            alt='astronaut'
            loading='lazy'
            decoding='async'
            data-nimg='1'
            style={{ color: 'transparent' }}
            src='/Default_Insanity_1-768x1152.webp'

            id='brxe-bxudom'


          />
        </div>

        <div className='mt-10 md:mt-0'>
          <h1 className='text-4xl md:text-6xl text-white font-bold text-center md:text-left'>
            Creativity, Unleashed.
          </h1>
          <h2 className='text-xl md:text-2xl text-white mt-4 text-center md:text-left'>
            Leverage generative AI with a unique suite of tools to
          </h2>
          <h2 className='text-xl md:text-2xl text-white mt-2 text-center md:text-left'>
            convey your ideas to the world.
          </h2>
          <div className='flex justify-center gap-14 md:justify-start mt-10'>

            <img
              src='/playstore-coming-soon.svg'
              className='brxe-image css-filter size-full lazyloaded w-32 md:w-60 cursor-pointer'
              onClick={
                () => {
                  window.open('https://play.google.com/store/apps/details?id=com.pixatwist', '_blank');
                }
              }
              alt=''
              id='brxe-zuwcim'
              decoding='async'
              data-src='/playstore-coming-soon.svg'
            />
          </div>
        </div>
      </div>

      <div className='bg-black p-4 rounded-xl w-11/12 md:w-9/12 h-64 md:h-full mt-10 mx-auto'>
        <img className=' w-full h-full object-cover' alt='im' src='/img1234.jpg' />
      </div>
    </div>
  );
};

export default BodyUpper;
