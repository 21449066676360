import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import BodyMiddle from './Compo/BodyMiddle';
import BodyUpper from './Compo/BodyUpper';
import FAQs from './Compo/FAQs';
import Footer from './Compo/Footer';
import Gallery from './Compo/Gallery';
import TermsOfUse from './Compo/T&C';
import Testimonials from './Compo/Testimonials';
import { useInView } from 'react-intersection-observer';
import PrivacyPolicy from './Compo/PrivacyPolicy';
import Slider from './Compo/Features';

const LazyLoad = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      style={{
        transition: 'opacity 500ms ease-in',
        opacity: inView ? 1 : 0,
        backgroundColor: 'black'
      }}
    >
      {children}
    </div>
  );
};

const Home = () => (
  <>
    <div class="max-w-full overflow-x-hidden" id="Home"></div>
    <LazyLoad><BodyUpper /></LazyLoad>
    <LazyLoad><BodyMiddle /></LazyLoad>



    <LazyLoad><Gallery /></LazyLoad>

    <LazyLoad><FAQs /></LazyLoad>
    <LazyLoad><Testimonials /></LazyLoad>
    <LazyLoad><Footer /></LazyLoad>
  </>
);

function App() {
  return (
    <Router>
      <div className="App scroll-smooth transition-all">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/termofuse" element={<TermsOfUse />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
