// src/Slider.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Slider = () => {
    return (
        <div className="slider-container w-full p-4">
            <Swiper
                spaceBetween={30}
                slidesPerView={1}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                }}
                navigation
                pagination={{ clickable: true }}
            >
                <SwiperSlide>
                    <div className="flex flex-col items-center text-center bg-white rounded-lg shadow-lg">
                        <img src="/images/image1.jpg" alt="Absolute Reality v1.6" className="w-full rounded-t-lg" />
                        <h3 className="my-4 text-xl font-bold">Absolute Reality v1.6</h3>
                        <p className="mb-4 text-gray-600">A photorealistic style model from Lykon. Great at all sorts of photorealism.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="flex flex-col items-center text-center bg-white rounded-lg shadow-lg">
                        <img src="/images/image2.jpg" alt="PixaTwist Diffusion" className="w-full rounded-t-lg" />
                        <h3 className="my-4 text-xl font-bold">PixaTwist Diffusion</h3>
                        <p className="mb-4 text-gray-600">A model with incredible shading and contrast, great at both photos and artistic styles, by cac0e.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="flex flex-col items-center text-center bg-white rounded-lg shadow-lg">
                        <img src="/images/image3.jpg" alt="DreamShaper v6" className="w-full rounded-t-lg" />
                        <h3 className="my-4 text-xl font-bold">DreamShaper v6</h3>
                        <p className="mb-4 text-gray-600">A new update to an incredibly versatile model, excels at both people and environments, by Lykon.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="flex flex-col items-center text-center bg-white rounded-lg shadow-lg">
                        <img src="/images/image4.jpg" alt="Deliberate 1.1" className="w-full rounded-t-lg" />
                        <h3 className="my-4 text-xl font-bold">Deliberate 1.1</h3>
                        <p className="mb-4 text-gray-600">A powerful model created by XpucT that is great for both photorealism and artistic creations.</p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default Slider;
