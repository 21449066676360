import React, { useState } from 'react';
import Modal from 'react-modal';

const Gallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    { src: '/image20.png', prompt: 'Sample prompt 1', model: 'Model 1' },
    { src: '/image12.png', prompt: 'Sample prompt 2', model: 'Model 2' },
    { src: '/image13.png', prompt: 'Sample prompt 3', model: 'Model 3' },
    { src: '/image14.png', prompt: 'Sample prompt 4', model: 'Model 4' },
    { src: '/image15.jpeg', prompt: 'Sample prompt 5', model: 'Model 5' },
    { src: '/image16.jpg', prompt: 'Sample prompt 6', model: 'Model 6' },
    { src: '/image17.jpg', prompt: 'Sample prompt 7', model: 'Model 7' },
    { src: '/image18.jpeg', prompt: 'Sample prompt 8', model: 'Model 8' },
    { src: '/image19.png', prompt: 'Sample prompt 9', model: 'Model 9' },
    { src: '/image21.png', prompt: 'Sample prompt 10', model: 'Model 10' },
  ];

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  return (
    <div id="Gallery" className="bg-gradient-to-r from-[#3E3677] to-[#06080D] p-4">
      <span className='flex justify-center gap-8'>
        <h1 className='text-yellow-300 text-4xl mt-10 mb-10'>
          Platform <p className='inline text-white'>Gallery</p>
        </h1>
        <svg className="brxe-svg mt-10 mb-10" id="brxe-uplnlm" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
          <path d="M33.834 14.4435C32.385 7.278 25.521 1.875 17.865 1.875C9.04796 1.875 1.875 9.1095 1.875 18C1.875 26.8905 9.108 34.125 18 34.125C19.7415 34.125 20.9805 33.363 21.3165 32.085C21.69 30.6645 20.793 29.145 18.975 28.119C18.504 27.8535 18.252 27.375 18.3615 26.9565C18.5025 26.4195 19.371 25.7925 21.351 25.7925H24.6885C25.875 25.7925 29.9325 25.563 32.364 22.602C34.014 20.592 34.509 17.8485 33.834 14.4435ZM30.6255 21.1725C28.8195 23.3715 25.626 23.5425 24.6885 23.5425H21.351C17.2515 23.5425 16.3605 25.719 16.185 26.385C15.8145 27.792 16.5075 29.31 17.868 30.078C18.942 30.684 19.191 31.317 19.14 31.5135C19.101 31.6635 18.72 31.875 17.9985 31.875C10.347 31.875 4.12354 25.6515 4.12354 18C4.12354 10.3485 10.287 4.125 17.8635 4.125C24.4695 4.125 30.387 8.75252 31.626 14.886C32.1645 17.5935 31.827 19.7085 30.6255 21.1725ZM27.405 15C27.405 16.035 26.565 16.875 25.53 16.875C24.495 16.875 23.6475 16.035 23.6475 15C23.6475 13.965 24.48 13.125 25.515 13.125H25.53C26.565 13.125 27.405 13.965 27.405 15ZM16.905 10.5C16.905 11.535 16.065 12.375 15.03 12.375C13.995 12.375 13.1475 11.535 13.1475 10.5C13.1475 9.465 13.98 8.625 15.015 8.625H15.03C16.065 8.625 16.905 9.465 16.905 10.5ZM22.905 10.5C22.905 11.535 22.065 12.375 21.03 12.375C19.995 12.375 19.1475 11.535 19.1475 10.5C19.1475 9.465 19.98 8.625 21.015 8.625H21.03C22.065 8.625 22.905 9.465 22.905 10.5ZM12.405 15C12.405 16.035 11.565 16.875 10.53 16.875C9.49503 16.875 8.64752 16.035 8.64752 15C8.64752 13.965 9.48001 13.125 10.515 13.125H10.53C11.565 13.125 12.405 13.965 12.405 15ZM12.405 21C12.405 22.035 11.565 22.875 10.53 22.875C9.49503 22.875 8.64752 22.035 8.64752 21C8.64752 19.965 9.48001 19.125 10.515 19.125H10.53C11.565 19.125 12.405 19.965 12.405 21Z" fill="url(#paint0_linear_3304_6591)"></path>
          <defs>
            <linearGradient id="paint0_linear_3304_6591" x1="3.09665" y1="8.66447" x2="29.0895" y2="4.65664" gradientUnits="userSpaceOnUse">
              <stop stop-color="#DB519E"></stop>
              <stop offset="0.499028" stop-color="#B14BF4"></stop>
              <stop offset="1" stop-color="#6E7BFC"></stop>
            </linearGradient>
          </defs>
        </svg>
      </span>
      <div className='flex flex-wrap -mx-1 overflow-hidden'>
        {images.map((image, index) => (
          <div key={index} className="px-1 my-2 w-full overflow-hidden sm:w-1/2 md:w-1/3 lg:w-1/4">
            <img
              className="w-full h-auto rounded-lg object-cover cursor-pointer"
              src={image.src}
              alt={`Gallery Image ${index}`}
              onClick={() => openModal(image)}
            />
          </div>
        ))}
      </div>
      {selectedImage && (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Image Modal" className="modal" overlayClassName="modal-overlay">
          <div className="bg-gradient-to-r from-[#6F64DD] to-[#0D070F] p-4 rounded-lg max-w-full max-h-screen overflow-auto">
            {/* <h2 className="text-xl font-bold mb-4 text-white">Image Details</h2> */}
            <div className="flex justify-center">
              <img className="w-auto h-auto max-w-full max-h-[60vh] rounded-lg mb-4" src={selectedImage.src} alt="Selected" />
            </div>
            {/* <p className="text-white"><strong>Prompt:</strong> {selectedImage.prompt}</p> */}
            {/* <p className="text-white"><strong>Model:</strong> {selectedImage.model}</p> */}
            <button onClick={closeModal} className="mt-4 bg-gradient-to-r from-[#6F64DD] to-[#0D070F] text-white py-2 px-4 rounded">Close</button>
          </div>
        </Modal>
      )}
      <style jsx>{`
        .modal {
          position: fixed;
          top: 50%;
          left: 50%;
          right: auto;
          bottom: auto;
          transform: translate(-50%, -50%);
          max-width: 500px;
          width: 90%;
          z-index: 1000;
          outline: none;
        }

        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.75);
          z-index: 999;
        }
      `}</style>
    </div>
  );
}

export default Gallery;
