import React from 'react';

const TermsOfUse = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scroll
        });
    };

    // run scrollToTop function when the component is rendered
    React.useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <div className="container space-y-4 bg-gradient-to-r from-[#6E66E3] to-[#91364A] min-h-screen ">
            <h1 className='text-xl font-semibold '>PixaTwist Terms of Use</h1>
            <p><strong>Last modified: June 2024</strong></p>

            <section>
                <h2 className=' text-lg font-bold'>I. INTRODUCTION</h2>
                <p className='text-white'>Welcome to PixaTwist! These terms of use ("Terms") apply to your access to and use of the PixaTwist website and mobile application (the "Service"). Please read them carefully.</p>
                <p className='text-white'>By accessing or using the Service, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, do not use the Service.</p>
            </section>

            <section>
                <h2 className=' text-lg font-bold'>II. ELIGIBILITY</h2>
                <p className='text-white'>You must be at least 18 years old to use the Service. By using the Service, you represent and warrant that you meet this age requirement.</p>
            </section>

            <section>
                <h2 className=' text-lg font-bold'>III. USER ACCOUNTS</h2>
                <p className='text-white'>To use certain features of the Service, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
                <p className='text-white'>You are responsible for safeguarding your account information and for any activities or actions under your account. You agree to notify us immediately of any unauthorized use of your account.</p>
            </section>

            <section>
                <h2 className=' text-lg font-bold'>IV. USER CONDUCT</h2>
                <p className='text-white'>You agree not to use the Service for any unlawful or prohibited purpose. You agree not to:</p>
                <ul className='text-white'>
                    <li>Violate any applicable laws or regulations.</li>
                    <li>Infringe the intellectual property rights of others.</li>
                    <li>Upload or distribute any viruses or malicious code.</li>
                    <li>Engage in any activity that disrupts or interferes with the Service.</li>
                    <li>Use the Service to transmit any spam or unsolicited communications.</li>
                </ul>
            </section>

            <section >
                <h2 className=' text-lg font-bold'>V. CONTENT</h2>
                <p className='text-white'>All content, including images, text, and graphics, on the Service is the property of PixaTwist or its licensors. You may not use, reproduce, distribute, or create derivative works from the content without our prior written permission.</p>
                <p className='text-white'>By posting or uploading content to the Service, you grant us a non-exclusive, royalty-free, worldwide, sublicensable, and transferable license to use, reproduce, distribute, and display such content in connection with the Service.</p>
            </section>

            <section>
                <h2 className=' text-lg font-bold'>VI. COPYRIGHT INFRINGEMENT</h2>
                <p className='text-white'>If you believe that your work has been copied in a way that constitutes copyright infringement, please contact us with the following information:</p>
                <ul className='text-white'>
                    <li>A description of the copyrighted work that you claim has been infringed.</li>
                    <li>A description of where the material you claim is infringing is located on the Service.</li>
                    <li>Your address, telephone number, and email address.</li>
                    <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li>
                    <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
                    <li>Your electronic or physical signature.</li>
                </ul>
            </section>

            <section>
                <h2 className=' text-lg font-bold'>VII. TERMINATION</h2>
                <p className='text-white'>We may terminate or suspend your access to the Service, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the Service will immediately cease.</p>
            </section>

            <section>
                <h2 className=' text-lg font-bold'>VIII. DISCLAIMER OF WARRANTIES</h2>
                <p className='text-white'>The Service is provided "as is" and "as available" without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
            </section>

            <section>
                <h2 className=' text-lg font-bold'>IX. LIMITATION OF LIABILITY</h2>
                <p className='text-white'>In no event shall PixaTwist, its directors, employees, or agents be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use of or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses, or the like that may be transmitted to or through our Service by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or (vi) any other matters relating to the Service.</p>
            </section>

            <section>
                <h2 className=' text-lg font-bold'>X. GOVERNING LAW</h2>
                <p className='text-white'>These Terms shall be governed and construed in accordance with the laws of the jurisdiction in which PixaTwist operates, without regard to its conflict of law provisions.</p>
            </section>

            <section>
                <h2 className=' text-lg font-bold'>XI. CHANGES TO TERMS</h2>
                <p className='text-white'>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                <p className='text-white'>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>
            </section>

            <section>
                <h2 className=' text-lg font-bold'>XII. CONTACT US</h2>
                <p className='text-white'>If you have any questions about these Terms, please contact us at <a href="mailto:support@pixatwist.com">support@pixatwist.com</a>.</p>
            </section>

            <footer>
                <p className=' text-lg font-bold'>© 2024 PixaTwist. All rights reserved.</p>
                <p className='text-white'><a href="/privacy-policy">Privacy Policy</a> | Terms of Use</p>
            </footer>
        </div>
    );
};

export default TermsOfUse;