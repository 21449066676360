import React, { useState } from "react";
import Modal from "react-modal";
import { ArrowLeft, ArrowRight } from "lucide-react";
import useEmblaCarousel from "embla-carousel-react";
import { Button } from "../ui/button";

const images = [
  { src: "/image1.jpeg", prompt: 'Sample prompt 1', model: 'Model 1' },
  { src: "/image3.jpeg", prompt: 'Sample prompt 2', model: 'Model 2' },
  { src: "/image4.jpeg", prompt: 'Sample prompt 3', model: 'Model 3' },
  { src: "/image5.jpeg", prompt: 'Sample prompt 4', model: 'Model 4' },
  { src: "/image6.jpeg", prompt: 'Sample prompt 5', model: 'Model 5' },
  { src: "/image7.jpeg", prompt: 'Sample prompt 6', model: 'Model 6' },
  { src: "/image8.jpeg", prompt: 'Sample prompt 7', model: 'Model 7' },
  { src: "/image9.jpeg", prompt: 'Sample prompt 8', model: 'Model 8' },
  { src: "/image10.jpeg", prompt: 'Sample prompt 9', model: 'Model 9' },
];

export function CarouselSpacing() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  const scrollPrev = () => {
    if (emblaApi) emblaApi.scrollPrev();
  };

  const scrollNext = () => {
    if (emblaApi) emblaApi.scrollNext();
  };

  return (
    <>
      <div className="relative mt-20 mx-auto max-w-screen-lg overflow-hidden">
        <div className="embla w-full md:w-[900px]" ref={emblaRef}>
          <div className="embla__container flex">
            {images.map((image, index) => (
              <div className="embla__slide flex-[0_0_100%] md:flex-[0_0_100%] lg:flex-[0_0_33.333%] p-2" key={index}>
                <div className="relative w-full h-[500px] pb-96">
                  <img
                    className="absolute top-0 left-0 w-[500px] h-96 object-cover rounded-lg cursor-pointer "
                    src={image.src}
                    alt={`Image ${index + 1}`}
                    onClick={() => openModal(image)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <Button
          variant="outline"
          size="icon"
          className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2 bg-white rounded-full shadow-md"
          onClick={scrollPrev}
        >
          <ArrowLeft className="h-6 w-6" />
          <span className="sr-only">Previous slide</span>
        </Button>
        <Button
          variant="outline"
          size="icon"
          className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2 bg-white rounded-full shadow-md"
          onClick={scrollNext}
        >
          <ArrowRight className="h-6 w-6" />
          <span className="sr-only">Next slide</span>
        </Button>
      </div>

      {selectedImage && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Modal"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <div className="bg-gradient-to-r from-[#6F64DD] to-[#0D070F] p-4 rounded-lg max-w-full max-h-screen overflow-auto">
            <div className="flex justify-center">
              <img
                className="w-auto h-auto max-w-full max-h-[60vh] rounded-lg mb-4"
                src={selectedImage.src}
                alt="Selected"
              />
            </div>
            <button
              onClick={closeModal}
              className="mt-4 bg-gradient-to-r from-[#6F64DD] to-[#0D070F] text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </Modal>
      )}

      <style jsx>{`
        .modal {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 500px;
          width: 90%;
          z-index: 1000;
          outline: none;
        }
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.75);
          z-index: 999;
        }
      `}</style>
    </>
  );
};

export default CarouselSpacing;
