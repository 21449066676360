import React from 'react'

const Testimonials = () => {
  return (

    <div id="Reviews">

      <div className='bg-[#1F222A]  pb-20 '>

        <div className='flex pt-10 justify-center text-2xl text-white font-medium  md:text-5xl '>
          What our users think
        </div>
        <div className='text-white  flex justify-center  mt-3'>
          <p className='text-lg ml-4 md:ml-0 md:text-2xl inline'>Find out how our platform has changed the AI Image Generation experience for diverse users.</p>

        </div >
        {/* cards */}
        <div className='bg-[#1F222A] flex overflow-hidden '>
          <div className=' flex space-x-6 animate-loop-scroll '>
            <div className='bg-[#121317] w-[350px] text-white h-64 mt-6 rounded-3xl'>
              <p className='mx-6 mt-4 text-white'> <p className='inline' >PixaTwist Art Generator is a great way to</p>
                <p> generate illustrations for articles, but sometimes</p>
                <p>the artistic style might not be a perfect fit. It </p>
                <p>  requires some browsing and refinement to find</p>
                <p>the right image.</p>
              </p>
              <div className='mt-6 mx-4 flex'>
                <img className="w-10 h-10 rounded-full" alt="Chloe Anderson" src="/4.webp" />
                <div className='mx-2'>
                  <p className='text-[14px]'>Cory Anderson</p>
                  <p className='text-[14px] text-[#8D9AA0]'>Freelancer<p className='inline ml-10 text-lg'>⭐⭐⭐⭐⭐</p></p>
                </div>
              </div>
            </div>


            <div className='bg-[#121317] w-[350px] text-white h-64 mt-6 rounded-3xl'>
              <p className='mx-6 mt-4 text-white'>
                <p>PixaTwist has revolutionized </p>
                <p>my creative process, provide endless</p>
                <p> design inspirations and accelerate </p>
                <p> my design iterations.</p>

              </p>
              <div className='mt-6 mx-4 flex'>
                <img alt='hui' className='w-10 h-10 rounded-full' src='/ktJXjP9keARlPKWIqzZwRZ8Iw.jpeg' />
                <div className='mx-2'>
                  <p className='text-[14px] text-white'>Barbara Smith</p>
                  <p className='text-[14px] text-[#8D9AA0]'>Entrepreneur <p className='inline ml-10 text-lg'>⭐⭐⭐⭐⭐</p></p>
                </div>
              </div>
            </div>


            <div className='bg-[#121317] w-[350px] h-64 text-white  mt-6 rounded-3xl'>
              <p className='mx-6 mt-4 text-white'>
                <p >PixaTwist is a fun and easy way to </p>
                <p> explore my creativity. I can create  </p>
                <p> fantastical landscapes, portraits, and even </p>
                <p> abstract art - all with just a few words.</p>

              </p>
              <div className='mt-6 mx-4 flex'>
                <img alt='hui' className='w-10 h-10 rounded-full' src='/de6tbOQ9ooGKsChQIfAjQbHPhSA.jpeg' />
                <div className='mx-2'>
                  <p className='text-[14px]'>Elizabeth Martin</p>
                  <p className='text-[14px] text-[#8D9AA0]'>Web Developer <p className='inline ml-4 text-lg'>⭐⭐⭐⭐⭐</p></p>
                </div>
              </div>
            </div>

          </div>
          <div className='bg-[#1F222A] flex mx-4 space-x-6 animate-loop-scroll  ' aria-hidden="true">
            <div className='bg-[#121317] text-white w-[350px] h-64 mt-6 rounded-3xl'>
              <p className='mx-6 mt-4  text-white'> <p >PixaTwist is like having a virtual mood </p>
                <p>board at my fingertips. I can generate </p>
                <p> different clothing styles and fabric   </p>
                <p> textures, helping me create unique  </p>
                <p> and cohesive fashion collections</p>

              </p>
              <div className='mt-6 mx-4 flex'>
                <img className='w-10 h-10 rounded-full' alt="David Lee" src="/2.webp" />
                <div className='mx-2'>
                  <p className='text-[14px]'>David Lee</p>
                  <p className='text-[14px] text-[#8D9AA0]'>Entrepreneur <p className='inline ml-10 text-lg'>⭐⭐⭐⭐⭐</p></p>

                </div>
              </div>
            </div>

            <div className='bg-[#121317] w-[350px] text-white h-64 mt-6 rounded-3xl'>
              <p className='mx-6 mt-4 text-white '> <p >PixaTwist is a dream come true </p>
                <p>for writers like me! It helps me </p>
                <p>visualize scenes and characters,  </p>
                <p>bringing my stories to life  </p>
                <p>in a whole new way.</p>
              </p>
              <div className='mt-6 mx-4 flex'>
                <img alt='hui' className='w-10 h-10 rounded-full' src='/KJ4l20D2KKaiJtFwSiFDX3XsQk.jpeg' />
                <div className='mx-2'>
                  <p className='text-[14px]'>Sarah Mitchell</p>
                  <p className='text-[14px] text-[#8D9AA0]'>Freelancer <p className='inline ml-10 text-lg'>⭐⭐⭐⭐⭐</p></p>
                </div>
              </div>
            </div>


            <div className='bg-[#121317] w-[350px] text-white h-64 mt-6 rounded-3xl'>
              <p className='mx-6 mt-4 text-white'> <p >We use PixaTwist to prototype product </p>
                <p>designs and user interfaces. It allows </p>
                <p>us to quickly iterate and test  </p>
                <p>  different concepts before investing time</p>
                <p>and resources into physical prototypes.</p>
              </p>
              <div className='mt-6 mx-4 flex'>
                <img alt='hui' className='w-10 h-10 rounded-full' src='/1.webp' />
                <div className='mx-2'>
                  <p className='text-[14px]'>Jane</p>
                  <p className='text-[14px] text-[#8D9AA0]'>Freelancer <p className='inline ml-10 text-lg'>⭐⭐⭐⭐⭐</p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* card row 2 */}
        <div className='flex overflow-hidden mt-6'>
          <div className=' flex space-x-6 animate-loop-scroll '>
            <div className='bg-[#121317] w-[350px] h-64 mt-6 rounded-3xl'>
              <p className='mx-6 mt-4 text-white'>
                <p>As a small business owner, attorney</p>
                <p>consultations were a nightmare due to </p>
                <p> high costs. AIL has been a game changer </p>
                <p> with its cost-effective solutions. Now, I</p>
                <p>can get the assistance without burning a</p>
                <p>hole in my pocket.</p>
              </p>
              <div className='mt-6 mx-4 flex'>
                <img alt='hui' className='w-10 h-10 rounded-full' src='/ZvOi93wDdqfqFYGmHZbkAukAcNg.jpeg' />
                <div className='mx-2'>
                  <p className='text-[14px] text-white'>Brian Lopez</p>
                  <p className='text-[14px] text-[#8D9AA0]'>Small Business Owner <p className='inline ml-10 text-lg'>⭐⭐⭐⭐⭐</p></p>
                </div>
              </div>
            </div>


            <div className='bg-[#121317] w-[350px] text-white h-64 mt-6 rounded-3xl'>
              <p className='mx-6 mt-4 text-white'>
                <p>PixaTwist is a fantastic AI generator </p>
                <p> for creating social media graphics </p>
                <p>and marketing materials. It helps me </p>
                <p>generate eye-catching visuals  </p>
                <p>that resonate with our target audience. </p>
                <p></p>
              </p>
              <div className='mt-6 mx-4 flex'>
                <img alt='hui' className='w-10 h-10 rounded-full' src='/3.webp' />
                <div className='mx-2'>
                  <p className='text-[14px] text-white'>Maria Garcia</p>
                  <p className='text-[14px] text-[#8D9AA0]'>Entrepreneur <p className='inline ml-6 text-lg'>⭐⭐⭐⭐⭐</p></p>
                </div>
              </div>
            </div>


            <div className='bg-[#121317] w-[350px] text-white h-64  mt-6 rounded-3xl'>
              <p className='mx-6 mt-4 text-white'>
                <p >PixaTwist helps me create captivating </p>
                <p>visuals for my blog posts. It allows me  </p>
                <p>to illustrate trends and preparing better</p>
                <p>  for my concepts and ideas in a way </p>
                <p> that keeps my readers engaged.</p>
              </p>
              <div className='mt-6 mx-4 flex'>
                <img alt='hui' className='w-10 h-10 rounded-full' src='/7.webp' />
                <div className='mx-2'>
                  <p className='text-[14px]'>Noah Martin</p>
                  <p className='text-[14px] text-[#8D9AA0]'>Blogger <p className='inline ml-10 text-lg'>⭐⭐⭐⭐⭐</p></p>
                </div>
              </div>
            </div>

          </div>

          <div className='bg-[#1F222A] flex mx-4 text-white space-x-6 animate-loop-scroll  '>

            <div className='bg-[#121317] w-[350px] h-64 text-white  mt-6 rounded-3xl'>
              <p className='mx-6 mt-4 text-white'>
                <p >PixaTwist is a fun and easy way to </p>
                <p> explore my creativity. I can create  </p>
                <p> fantastical landscapes, portraits, and even </p>
                <p> abstract art - all with just a few words.</p>

              </p>
              <div className='mt-6 mx-4 flex'>
                <img alt='hui' className='w-10 h-10 rounded-full' src='/de6tbOQ9ooGKsChQIfAjQbHPhSA.jpeg' />
                <div className='mx-2'>
                  <p className='text-[14px]'>Elizabeth Martin</p>
                  <p className='text-[14px] text-[#8D9AA0]'>Web Developer <p className='inline ml-4 text-lg'>⭐⭐⭐⭐⭐</p></p>
                </div>
              </div>
            </div>

            <div className='bg-[#121317] w-[350px] text-white h-64 mt-6 rounded-3xl'>
              <p className='mx-6 mt-4 text-white'> <p >We use PixaTwist to prototype product </p>
                <p>designs and user interfaces. It allows </p>
                <p>us to quickly iterate and test  </p>
                <p>  different concepts before investing time</p>
                <p>and resources into physical prototypes.</p>
              </p>
              <div className='mt-6 mx-4 flex'>
                <img alt='hui' className='w-10 h-10 rounded-full' src='/1.webp' />
                <div className='mx-2'>
                  <p className='text-[14px]'>Jane</p>
                  <p className='text-[14px] text-[#8D9AA0]'>Freelancer <p className='inline ml-10 text-lg'>⭐⭐⭐⭐⭐</p></p>
                </div>
              </div>
            </div>

            <div className='bg-[#121317] w-[350px] text-white h-64 mt-6 rounded-3xl'>
              <p className='mx-6 mt-4 text-white '> <p >PixaTwist is a dream come true </p>
                <p>for writers like me! It helps me </p>
                <p>visualize scenes and characters,  </p>
                <p>bringing my stories to life  </p>
                <p>in a whole new way.</p>
              </p>
              <div className='mt-6 mx-4 flex'>
                <img alt='hui' className='w-10 h-10 rounded-full' src='/KJ4l20D2KKaiJtFwSiFDX3XsQk.jpeg' />
                <div className='mx-2'>
                  <p className='text-[14px]'>Sarah Mitchell</p>
                  <p className='text-[14px] text-[#8D9AA0]'>Freelancer <p className='inline ml-10 text-lg'>⭐⭐⭐⭐⭐</p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials