import React from 'react';

const PrivacyPolicy = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scroll
        });
    };

    // run scrollToTop function when the component is rendered
    React.useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <div className="container bg-gradient-to-r from-[#6E66E3] to-[#91364A] space-y-4">
            <h1 className='text-xl font-bold'>PixaTwist Privacy Policy</h1>
            <p><strong>Last modified: June 2024</strong></p>

            <p>Opt-out options: DO NOT USE MY DATA</p>

            <section>
                <h2 className='font-bold text-lg'>I. INTRODUCTION</h2>
                <p className='text-white'>PixaTwist (“we,” “us” or “our”) takes your privacy seriously. This Privacy Policy (“Privacy Policy”) explains our data protection policy and describes the types of information we may process when you access and/or use the PixaTwist app and website.</p>
                <p className='text-white'>When we refer to personal data (or personal information) we mean any information of any kind relating to a natural person who can be identified, directly or indirectly, in particular by reference to such data.</p>
                <p className='text-white'>The scope of the data we process may vary depending on the types of actions and operations you perform using the Website (see Section II for more details). The provisions of this Privacy Policy are applicable to both active users of the Website and to any other persons who visit the Website (collectively, “Users”).</p>
                <p className='text-white'>For the purposes of the GDPR, we are the data controller, unless otherwise stated.</p>
                <p><strong>PLEASE READ THE FOLLOWING PRIVACY POLICY, FOR INFORMATION REGARDING THE WAYS YOUR PERSONAL INFORMATION MAY BE PROCESSED, CAREFULLY. WHEN YOU USE THE WEBSITE YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS PRIVACY POLICY.</strong></p>
                <p className='text-white'>If you are a California resident, please read the following important notice under the California Consumer Privacy Act of 2018 (CCPA).</p>
            </section>

            <section>
                <h2 className='font-bold text-lg'>II. INFORMATION WE PROCESS</h2>
                <h3 className='font-semibold'>Functional Information</h3>
                <p className='text-white'>We ask for and process the following information when you access and/or use the Website. This information is necessary for the adequate performance of the contract between you and us. Without such information it is impossible to provide complete functionality of the Website and perform the requested services.</p>
                <ul className='text-white'>
                    <li>Contact Information (name, e-mail address, as well as any other content included in the email) which you may fill in by yourself when you contact us via our “Contact Us” form available on the Website. We collect, store and process it by our cloud storage provider (Amazon.com, Inc.). We use such information to respond effectively to your inquiry, fulfill your requests, and send you communications that you request and perform the requested services.</li>
                </ul>

                <h3 className='mt-4 font-semibold'>Information That Is Processed Automatically</h3>
                <p className='text-white'>When you access and/or use the Website, some information about your device and your user behavior may be processed automatically. This information is generally non-personal, i.e. it does not, on its own, permit direct association with any specific individual, and we may access it only in aggregated form. We process this information on the ground of our legitimate interest for improving the Website and giving our users the best experience.</p>
                <p className='text-white'>We may use third-party automatic data processing technologies to analyze certain information sent by your device via the Website (advertising or analytics tools). Some of them may launch automated processing of your personal data, including profiling, which means any form of automated processing of personal data used to evaluate certain personal aspects relating to you, in particular to analyze or predict aspects concerning your personal preferences, interests, behavior, location or movements (see the list of data described below). Processing information through automatic data processing technologies starts automatically when you first time access the Website.</p>
                <ul >
                    <li className='mt-4'><strong>Device Details</strong>: type of your device, type of operating system and its version, model and manufacturer, screen size, screen density, orientation, audio volume and battery, battery life, loading time, latency, framerate, device memory usage.</li>
                    <li><strong>Internet connection details</strong>: mobile carrier, network provider, network type, IP address, timestamp and duration of sessions, speed, browser.</li>
                    <li><strong>Location-related information</strong>: IP address, the country code/ region/ state/ city associated with your SIM card or your device, language setting, time zone, neighboring commercial points of interest (e.g. “coffee shop”).</li>
                    <li><strong>Device identifiers</strong>: Identity For Advertisers for iOS devices/ Advertising ID or Android ID for Android devices, user identifiers.</li>
                </ul>
                <p><strong>Cookies and similar technologies</strong>: When you access and/or use the Website, cookies and similar technologies may be used (pixels, web beacons, scripts). A cookie is a text file containing small amounts of information which is downloaded to your device when you access the Website. The text file is then sent back to the server each time you use the Website. This enables us to operate the Website more effectively. For example, we will know how many users access specific areas or features within our Website and which links they clicked on. We use this aggregated information to understand and optimize how our Website is used, improve our marketing efforts, and provide content and features that are of interest to you. Third party analytics tools may use cookies or similar technologies for the purpose of analyzing the Website traffic.</p>
                <p><strong>Log file information</strong>: Log file information is automatically reported each time you make a request to access the Website. When you use the Website, analytics tools automatically record certain log file information, including time and date when you start and stop using the Website, and how you interact with the Website.</p>
                <p><strong>Ad-related information</strong>: The following data might be reported about the ads you can view: the date and time a particular ad is served; a record if that ad was “clicked” or if it was shown as a “conversion” event; what the ad offer is about; what type of ad it is (e.g., text, image, or video); which ad placement is involved (where the ad offer is displayed within the App); whether you respond to the ad.</p>
                <p>Information provided automatically to advertising or analytics tools does not generally come to our control, therefore, we cannot be responsible for processing such information. Please mind that some services are engaged in personal data profiling and may obtain information related to your personality and/or your device by using technologies that do not belong to our scope of responsibility.</p>
            </section>

            <section>
                <h2 className='font-bold text-lg'>III. THE PURPOSES OF PROCESSING YOUR PERSONAL DATA</h2>
                <ul className='text-white'>
                    <li>To make our service available and ensure the efficient operation of the Website.</li>
                    <li>To improve, test and monitor the effectiveness of our Website.</li>
                    <li>To provide you with interest-based (behavioral) advertising or other targeted content.</li>
                    <li>To communicate with you.</li>
                    <li>To prevent fraud and spam, to enforce the law.</li>
                </ul>
                <p className='text-white'>If any new purpose for processing your personal data arises we will let you know when we start to process information for that other purpose by introducing the corresponding changes to this Privacy Policy.</p>
            </section>

            <section>
                <h2 className='font-bold text-lg'>IV. SHARING OF YOUR INFORMATION</h2>
                <p className='text-white'>We will share your information with third parties only in the ways that are described in this Privacy Policy.</p>



                <ul className='text-white'>
                    <li>As required by law;</li>
                    <li>When we believe, in good faith, that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</li>
                    <li>In some cases, we may share your information with our affiliates, subsidiaries, contractors, business partners or other third parties who perform services on our behalf.</li>
                </ul>
            </section>

            <section>
                <h2 className='font-bold text-lg'>V. INTERNATIONAL DATA TRANSFERS</h2>
                <p className='text-white'>We work in the cross-border area and provide the Website to users around the world. We and third-party organizations that provide automatic data processing technologies for the Website or our third-party partners may transfer processed information across borders and from your country or jurisdiction to other countries or jurisdictions around the world.</p>
                <p className='text-white'>If you are located in the European Union or other regions with laws governing data processing that may differ from U.S. law, please note that we may transfer information, including personal information, to a country and jurisdiction that does not have the same data protection laws as in your jurisdiction.</p>
                <p className='text-white'>By using the Website you agree that we may transfer your personal information to a third country, a territory or one or more specified sectors within that third country, or to the international organization where data protection and confidentiality regulations may not provide the same level of protection of personal data as your country does.</p>
                <p className='text-white'>We try to make sure that the recipient of any personal data provides a proper protection of the personal data received, in accordance with the current legislation on the protection of such information.</p>
            </section>

            <section>
                <h2 className='font-bold text-lg'>VI. HOW LONG WE USE YOUR PERSONAL DATA</h2>
                <p className='text-white'>We generally retain your personal information for as long as is necessary for performing the functional service of the Website and to comply with our legal obligations. If you no longer want us to use your information that we physically access and store, you can request that we erase your personal information.</p>
                <p className='text-white'>However, some data may still be stored for a certain time period (but no longer than the storage purpose requires) if information is necessary to comply with legal obligation (taxation, accounting, audit) or in order to maintain safety and data backup settings, prevent fraud or other malicious acts.</p>
            </section>

            <section>
                <h2 className='font-bold text-lg'>VII. EXERCISING YOUR RIGHTS</h2>
                <p className='text-white'>Applicable data protection laws give you certain rights regarding your personal information. You have the following rights in relation to your personal information that was collected:</p>
                <ul>
                    <li><strong>Data Access and Portability</strong>. You can request copies of your personal information.</li>
                    <li><strong>Change or Correct Data</strong>. Where you cannot update data by yourself, you have the right to ask to correct, change, update or rectify your data.</li>
                    <li><strong>Data Retention and Deletion</strong>. The user data is generally retained for as long as your user profile is in existence or as it is needed to provide the relevant services. However, specific retention times can vary based on context of the processing performed. You have the right to ask to delete all or some of the personal data that is held about you.</li>
                    <li><strong>Restriction of Processing</strong>. Under certain circumstances, you may have the right to limit the ways in which your personal information is used.</li>
                    <li><strong>Exercising the right to know about data sharing</strong>. You have the right to know whether your data is shared with third parties. Detailed information on this issue is contained in section IV of the present Privacy Policy, however, if you have further questions in this regard, you can contact us at any time.</li>
                    <li><strong>Lodge a complaint</strong>. You can lodge a complaint with a supervisory authority if you consider that the processing of personal data relating to you infringes the GDPR.</li>
                </ul>
                <p>To exercise any of the rights described above, you can contact us via our “Contact Us” form available on the Website. Please bear in mind that we ensure the above mentioned rights only with respect to the information that we physically access and store.</p>
            </section>

            <section>
                <h2 className='font-bold text-lg'>VIII. HOW TO OPT OUT</h2>
                <p className='text-white'>You may prevent and/or manage the collection and processing of some Information related to your use of the Website following the instructions provided below. Note that it is applicable only with respect to the Information that is processed automatically.</p>
                <p ><strong>Google Analytics</strong>. If you want to opt-out of being tracked by Google Analytics you can install and enable Google Analytics Opt-out Browser Add-on. Additionally, you can manage cookies and/or delete cookies already set by Google Analytics through your browser settings. Further information on Google Analytics’ data practices and relevant instructions can be found at <a href="https://support.google.com/analytics/answer/6004245">Google Analytics Help Center</a>.</p>
                <p>Bear in mind that if you choose to disable all cookies, this can affect the Website functions, some of the features that make the Website more efficient may not function.</p>
            </section>

            <section>
                <h2 className='font-bold text-lg'>IX. SECURITY</h2>
                <p className='text-white'>The security of your personal information is highly important to us. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. We take reasonable and appropriate measures to protect personal information from loss, misuse and unauthorized access, disclosure, alteration and destruction, taking into account the risks involved in the processing and the nature of the personal information.</p>
                <p className='text-white'>We implement appropriate technical and organizational measures, which are designed to implement data-protection principles, such as data minimization, in an effective manner and to integrate the necessary safeguards into the processing. We seek your personal data to be encrypted with proper and strong encryption algorithms, including hashing where possible.</p>
                <p className='text-white'>Unfortunately, no method of transmission over the Internet, or method of electronic storage, is 100% secure. We do our best to protect your personal data, nevertheless, we cannot guarantee its absolute security. In the event that your personal information is compromised as a breach of security, we will promptly notify you in compliance with applicable law.</p>
                <p className='text-white'>If you have any questions about the security of the Website, you can contact us via our “Contact Us” form available on the Website.</p>
            </section>

            <section>
                <h2 className='font-bold text-lg'>X. CHILDREN’S PRIVACY</h2>
                <p className='text-white'>Our Website is not intended for children under the age of eighteen (18). Therefore, we do not knowingly collect or solicit any personal information from children under eighteen (18). No one under the age of eighteen (18) may provide any personal information to the Website. If you are under the age of eighteen (18), do not provide any information on the Website or through any of its features. Do not provide any information about yourself, including your email address. If we learn that we have collected personal information from a child under the age of eighteen (18) without verification of parental consent, we will erase that information as quickly as possible. If you believe that we might have any information from or about a child under eighteen (18), please contact us.</p>
            </section>

            <section>
                <h2 className='font-bold text-lg'>XI. CHANGES TO THE PRIVACY POLICY</h2>
                <p className='text-white'>This Privacy Policy is updated regularly. Whenever we change this Privacy Policy, we will post those changes to this Privacy Policy and other places that we consider appropriate. Additional forms of notice of modifications or updates as appropriate under the circumstances may be provided to you.</p>
            </section>

            <section>
                <h2 className='font-bold text-lg'>XII. HOW TO CONTACT US</h2>
                <p className='text-white'>If you have any questions about this Privacy Policy, please feel free to contact us via our “Contact Us” form available on the Website.</p>
                <p className='text-white'>Support Email: <a href="mailto:support@pixatwist.com">support@pixatwist.com</a></p>
            </section>

            <footer>
                <p >Copyright © 2024 PixaTwist. All rights reserved.</p>
                <p >Privacy Policy | Terms of Use</p>
            </footer>
        </div>
    );
};

export default PrivacyPolicy;